import React from 'react'
import pdf from "../../Assets/annualreturn/Annual Return 23-24.pdf"
import logo from "../../Assets/gemlogosmall.png"

const Investors = () => {
    const ShowFields = (value) => {
        const sectionannualreturn = document.getElementById("annualreturnDiv")
        const sectionpolicies = document.getElementById("policiesDiv")

        switch (value) {
            case "annualreturn":
                sectionannualreturn.style.display = "flex"
                sectionpolicies.style.display = "none"
                break;
            case "policies":
                sectionannualreturn.style.display = "none"
                sectionpolicies.style.display = "flex"
                break;

            default:
                sectionannualreturn.style.display = "flex"
                sectionpolicies.style.display = "none"
                break;
        }
    }
    return (
        <div className='pt-[10%] md:pt-[5%] min-h-[80vh] flex justify-center'>
            <div className='w-[80%] pt-[5%]'>
                <h3 className='indent-2 font-martelBold text-lg md:text-2xl text-[#102b58]'>Investors</h3>
                <div className='relative w-full h-[1px] mt-4 bg-gradient-to-r from-transparent via-[#102b58] to-transparent'>
                    {/* <img src={logo} alt='' className='absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 bg-white' /> */}
                </div>
                <div className='flex justify-center gap-3 mt-5 p-4'>
                    <div>
                        <input type="radio" id="annualreturn" name="Investors" value="annualreturn" className='peer hidden' defaultChecked />
                        <label htmlFor="annualreturn" onClick={() => ShowFields("annualreturn")} className='select-none py-2 px-6 border border-[#102b58] font-gothamLight text-sm rounded-md peer-checked:bg-[#102b58] peer-checked:text-white cursor-pointer'>Annual return</label>
                    </div>
                    {/* <div>
                        <input type="radio" id="policies" name="Investors" value="policies" className='peer hidden' />
                        <label htmlFor="policies" onClick={() => ShowFields("policies")} className='select-none py-2 px-6 border border-[#102b58] font-gothamLight text-sm rounded-md peer-checked:bg-[#102b58] peer-checked:text-white cursor-pointer'>Policies</label>
                    </div> */}
                </div>
                <div id='annualreturnDiv' className='p-[3%] flex justify-center flex-wrap gap-4'>
                    <PDfTemplate Returnyear="Annual Return 2023-24" />
                </div>
                <div id='policiesDiv' className='p-[3%] hidden justify-center flex-wrap gap-4'>
                    <PDfTemplate Returnyear="Gem Experience Remuneration Policy" />
                    <PDfTemplate Returnyear="Gem Experience Return Policy" />
                    <PDfTemplate Returnyear="Gem Experience Cookie Policy" />
                </div>
            </div>
        </div>
    )
}

const PDfTemplate = ({ Returnyear, Filelink }) => {
    return (
        <div className='group bg-[#102a5829] hover:bg-[#102a584b] py-4 h-fit min-w-[40%] px-6 flex items-center justify-between font-martel rounded-md select-none text-xs md:text-base'>
            <div className='flex items-center gap-2'>
                <svg width="35" height="35" viewBox="0 0 0.657 0.657" fill="none"><path d="M.109.284V.262H.087v.022zm.175 0V.262H.262v.022zm0 .175H.262v.022h.022zM.591.153h.022v-.01L.606.137zM.459.021.475.006.468 0H.459zm-.35.286h.044V.262H.109zm.022.175v-.11H.087v.11zm0-.109V.285H.087v.087zM.153.351H.108v.044h.044zM.174.329a.02.02 0 0 1-.022.022v.044A.066.066 0 0 0 .219.328zM.153.307a.02.02 0 0 1 .022.021h.044A.066.066 0 0 0 .153.262zM.262.284v.175h.044V.284zm.022.197h.044V.437H.284zm.11-.065V.328H.35v.087zM.328.262H.284v.044h.044zm.066.066A.066.066 0 0 0 .328.262v.044A.02.02 0 0 1 .35.328zM.328.482A.066.066 0 0 0 .394.416H.35a.02.02 0 0 1-.022.022zm.11-.22v.219h.044V.262zM.46.306h.11V.262H.46zm0 .087h.065V.35H.459zM.087.219V.066H.044v.153zM.569.153v.066h.044V.153zM.109.044h.35V0h-.35zM.444.038.576.17.607.139.475.007zM.088.066A.02.02 0 0 1 .109.044V0a.066.066 0 0 0-.065.066zM.044.525v.066h.044V.525zM.11.657h.438V.612H.11zM.612.591V.525H.568v.066zM.547.657A.066.066 0 0 0 .612.591H.568a.02.02 0 0 1-.022.022zM.044.591a.066.066 0 0 0 .065.066V.612A.02.02 0 0 1 .087.591z" fill="#000" /></svg>
                <div>{Returnyear}</div>
            </div>
            <a href={pdf} target='_blank' className='cursor-pointer'>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <path d="M5 12s2.545-5 7-5c4.454 0 7 5 7 5s-2.546 5-7 5-7-5-7-5" className='opacity-0 group-hover:opacity-100' />
                    <path d="M12 13a1 1 0 1 0 0-2 1 1 0 0 0 0 2m9-5V5a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v3m18 8v3a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-3" />
                </svg>
            </a>
        </div>
    )
}

export default Investors